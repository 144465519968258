html,
body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: #444;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  min-width: 320px;
  background: #fff;
  display: flex !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: URL('assets/img/ce-bg.png');
}

a {
  color: #2e9f64;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}