a {
  color: #000 !important;
  text-decoration: none !important;
}

.MuiButton-root {
  text-transform: none !important;
}

.MuiButton-iconSizeMedium > *:first-child {
  font-size: 24px !important;
}
